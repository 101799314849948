import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    Benvinguts al curs especialitzat per a agents “Interins de Policies Locals i
                    Guàrdies Urbanes”, dissenyat per proporcionar una formació integral a les{" "}
                    <strong>tècniques i metodologies policials actualitzades</strong>.
                </p>

                <p>
                    Aquest programa se centra en els principis bàsics d'actuació policial,
                    assegurant que els agents interins estiguin equipats amb el coneixement i les
                    habilitats necessàries per realitzar les seves funcions amb eficàcia i
                    professionalitat.
                </p>

                <p>Els participants rebran instrucció a:</p>

                <ul>
                    <li>
                        <strong>Metodologia</strong> per a l'ús adequat del material de dotació.
                    </li>
                    <li>
                        <strong>Coordinació</strong> efectiva amb companys (treball en binomi).
                    </li>
                    <li>
                        <strong>Tècniques actualitzades</strong> amb les impartides a l'ISPC de
                        registre de persones i situacions.
                    </li>
                    <li>
                        <strong>Procediments de detenció</strong> i conducció de persones.
                    </li>
                    <li>
                        <strong>Resolució de casos pràctics</strong>, enfocant en situacions reals.
                    </li>
                    <li>
                        <strong>Escenaris personalitzats</strong> per aplicar coneixements en un
                        entorn controlat.
                    </li>
                </ul>

                <p>
                    El curs també inclourà sessions interactives on els agents podran practicar i
                    perfeccionar les seves habilitats, garantint una preparació completa per
                    enfrontar els desafiaments del treball policial diari.
                </p>

                <p>
                    Amb aquest curs, els agents interins estaran a punt per realitzar la feina amb{" "}
                    <strong>confiança i responsabilitat</strong>, mantenint sempre el respecte pels
                    drets i la seguretat dels ciutadans.
                </p>

                <p>
                    Els formadors són professionals en actiu de les forces i cossos de seguretat,
                    titulats i amb una àmplia trajectòria professional, tant a l'àrea de seguretat
                    ciutadana com a la d'ordre públic.
                </p>

                <p>Uneix-te a nosaltres per marcar la diferència!</p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara't per destacar amb el nostre curs especialitzat per agents interins de Policies Locals i Guàrdies Urbanes. Adquireix les tècniques i coneixements actualitzats per actuar amb eficàcia i professionalitat, sota la guia de professionals en actiu. Inscriu-te ara i marca la diferència en el teu servei!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
